import {
  Autocomplete,
  Container,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import MetricsChart from "../../molecules/MetricsChart";
import { useEffect, useState } from "react";
import {
  useGetMetricsLazyQuery,
  useGetUserByOrganizationLazyQuery,
  User,
  Metrics,
  Transfers,
  useGetTransfersLazyQuery,
  Role,
  useGetUsageReportLazyQuery,
} from "../../generated/graphql";
import TableData from "../../molecules/TableData";
import dayjs, { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { PieChart } from "@mui/x-charts/PieChart";
import Button from "@mui/material/Button";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { generateCSVFile } from "../../util/utils";
import JSZip from "jszip";
import { saveAs } from "file-saver";

const MetricsView = () => {
  const today = new Date();
  const yyyy = today.getFullYear();
  const mm = String(today.getMonth() + 1).padStart(2, "0");
  const dd = String(today.getDate()).padStart(2, "0");

  const formattedDate = `${yyyy}-${mm}-${dd}`;
  const [selectedUser, setSelectedUser] = useState("all");
  const [userList, setUserList] = useState<User[]>([]);
  const [metrics, setMetrics] = useState<Metrics>();
  const [start, setStart] = useState<Dayjs | null>(dayjs("2024-01-01"));
  const [end, setEnd] = useState<Dayjs | null>(dayjs(formattedDate));
  const [transfers, setTransfer] = useState<Transfers[]>([]);
  const [loading, setLoading] = useState(false);
  const [downloadloading, setDownloadLoading] = useState(false);

  const [getUsageReport] = useGetUsageReportLazyQuery({
    fetchPolicy: "no-cache",
    async onCompleted(data) {
      try {
        const zip = new JSZip();
        if (data.getUsageReport?.seen) {
          const csv = generateCSVFile(data.getUsageReport?.seen);
          zip.file("Seen_Report.csv", csv);
        }
        if (data.getUsageReport?.down) {
          const csv = generateCSVFile(data.getUsageReport?.down);
          zip.file("Download_Report.csv", csv);
        }
        const blob = await zip.generateAsync({ type: "blob" });
        saveAs(blob, `Report.zip`);
      } catch (e) {
        console.log(e);
      }
      setLoading(false);
    },
    onError(error) {
      setLoading(false);
    },
  });

  const handleDownloadClick = async () => {
    setLoading(true);
    getUsageReport({
      variables: {
        id: selectedUser,
        start: start?.format() ?? "",
        end: end?.format() ?? "",
      },
    });
  };

  const [getUserMetrics] = useGetMetricsLazyQuery({
    onCompleted(data) {
      setMetrics(data.getMetrics as Metrics);
    },
    onError(error) {
      console.log(error);
    },
  });
  const [getOrganizationUsers] = useGetUserByOrganizationLazyQuery({
    fetchPolicy: "no-cache",
    onCompleted(data) {
      if (data.getUserByOrganization)
        setUserList([
          ...(data.getUserByOrganization as User[]),
          {
            id: "all",
            email: "All Users",
            createdAt: "",
            name: "",
            role: Role.User,
            updatedAt: "",
          },
        ]);
    },
    onError(error) {
      console.log(error);
    },
  });
  const handleSelect = (value: string) => {
    setSelectedUser(value);
  };

  const [getTransfers] = useGetTransfersLazyQuery({
    onCompleted(data) {
      setTransfer(data.getTransfers as Transfers[]);
    },
  });
  const getOptionLabel = (id: string) => {
    if (id === "all") {
      return "All Users";
    }
    const user = userList.find((item) => item.id === id);
    return user?.email ? user?.email : "";
  };
  useEffect(() => {
    if (userList.length === 0) getOrganizationUsers();
    getUserMetrics({
      variables: {
        userId: selectedUser,
        start: start?.format() ?? "",
        end: end?.format() ?? "",
      },
    });
    getTransfers({
      variables: {
        start: start?.format() ?? "",
        end: end?.format() ?? "",
      },
    });
  }, [selectedUser, start, end]);

  return (
    <Container>
      <Paper
        elevation={6}
        className="min-h-[500px] bg-common-white rounded-sm p-5"
      >
        <div className="flex p-2 justify-between">
          <Typography variant="h3">User Metrics</Typography>
          <div className="min-w-[300px]">
            <Autocomplete
              size="small"
              disablePortal
              options={[...userList]
                ?.sort((a, b) => a.email.localeCompare(b.email))
                .map((item) => item?.id)}
              getOptionLabel={getOptionLabel}
              fullWidth
              value={selectedUser}
              onChange={(event, newValue) => {
                handleSelect(newValue as string);
              }}
              renderInput={(params) => <TextField {...params} label="User" />}
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <div className="flex mt-2 gap-2">
                <DatePicker
                  value={start}
                  onChange={(newValue) => setStart(newValue)}
                />
                <DatePicker
                  value={end}
                  onChange={(newValue) => setEnd(newValue)}
                />
              </div>
            </LocalizationProvider>
            <div className="mt-2">
              <Button
                variant="contained"
                startIcon={<CloudDownloadIcon />}
                onClick={handleDownloadClick}
                disabled={loading}
                fullWidth
              >
                {loading ? "Downloading..." : "Download User Data"}
              </Button>
            </div>
            {/* </FormControl> */}
          </div>
        </div>
        <Grid
          container
          spacing={2}
          className="bg-gray-100 rounded-md mt-5 p-5 border-common-black border-1"
        >
          <Grid item xs={3}>
            <div className="flex flex-col justify-around h-full p-2">
              <div className="flex flex-col p-2 border-common-black border-1 rounded-md bg-common-white">
                <Typography variant="h6" className="text-primary-main">
                  Profiles Viewed
                </Typography>
                {selectedUser === "all" ? (
                  <Typography variant="h5">{metrics?.totalViewed}</Typography>
                ) : (
                  <Typography variant="h5">
                    {metrics?.viewed?._count?.reduce(
                      (accumulator, currentObject) => {
                        return (accumulator ?? 0) + (currentObject ?? 0);
                      },
                      0
                    )}
                  </Typography>
                )}
              </div>

              <div className="flex flex-col p-2 border-common-black border-1 rounded-md bg-common-white">
                <Typography variant="h6" className="text-primary-main">
                  Total Downloads
                </Typography>
                {selectedUser === "all" ? (
                  <Typography variant="h5">
                    {metrics?.totalDownloaded}
                  </Typography>
                ) : (
                  <Typography variant="h5">
                    {metrics?.downloaded?._count?.reduce(
                      (accumulator, currentObject) => {
                        return (accumulator ?? 0) + (currentObject ?? 0);
                      },
                      0
                    )}
                  </Typography>
                )}
              </div>
              <div className="flex flex-col p-2 border-common-black border-1 rounded-md bg-common-white">
                <Typography variant="h6" className="text-primary-main">
                  Total Searches
                </Typography>
                {selectedUser === "all" ? (
                  <Typography variant="h5">{metrics?.totalSearched}</Typography>
                ) : (
                  <Typography variant="h5">
                    {metrics?.searched?._count?.reduce(
                      (accumulator, currentObject) => {
                        return (accumulator ?? 0) + (currentObject ?? 0);
                      },
                      0
                    )}
                  </Typography>
                )}
              </div>
            </div>
          </Grid>
          <Grid item xs={9}>
            <MetricsChart metrics={metrics} />
          </Grid>

          <Grid item xs={12}>
            <TableContainer component={Paper}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">
                      <b>Candidates</b>
                    </TableCell>
                    <TableCell align="center">
                      <b>Resumes</b>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {transfers?.map((row) => (
                    <TableRow
                      key={row?.candidates}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell component="th" scope="row" align="center">
                        {row?.candidates}
                      </TableCell>
                      <TableCell align="center">{row?.resumes}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h6" className="text-primary-main text-center">
              Gender
            </Typography>
            <PieChart
              series={[
                {
                  data: [
                    { id: 0, value: 765871, label: "Male" },
                    { id: 1, value: 112784, label: "Female" },
                    { id: 2, value: 372215, label: "Not Specified" },
                  ],
                },
              ]}
              width={400}
              height={200}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h6" className="text-primary-main text-center">
              Age
            </Typography>
            <PieChart
              series={[
                {
                  data: [
                    { id: 0, value: 552943, label: "Unspecified" },
                    { id: 1, value: 372215, label: "18-24" },
                    { id: 2, value: 340338, label: "25-34" },
                    { id: 3, value: 102409, label: "35-44" },
                    { id: 4, value: 26407, label: "45-54" },
                    { id: 5, value: 5027, label: "55 and above" },
                  ],
                },
              ]}
              width={400}
              height={200}
            />
          </Grid>
          <Grid item xs={4}>
            <Typography
              variant="h6"
              className="text-primary-main flex justify-around"
            >
              Top Keywords
            </Typography>
            <TableData
              data={metrics?.keywords}
              label1={"Keywords"}
              label2={"Searched"}
            />
          </Grid>
          <Grid item xs={4}>
            <Typography
              variant="h6"
              className="text-primary-main flex justify-around"
            >
              Top Job Codes
            </Typography>
            <TableData
              data={metrics?.searchedjobCodes}
              label1={"Job Codes"}
              label2={"Viewed"}
            />
          </Grid>
          <Grid item xs={4}>
            <Typography
              variant="h6"
              className="text-primary-main flex justify-around"
            >
              Top Job Codes Globally
            </Typography>
            <TableData
              data={metrics?.searchedjobCodesGlobal}
              label1={"Job Codes"}
              label2={"Viewed"}
            />
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

export default MetricsView;
